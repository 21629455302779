main {
  flex: 1 0 auto;
  margin-top: 10px;
  margin-bottom: 10px;
}

header {
  height: 50px;
  flex-shrink: 0;
  background-color: #134c7d;
}

footer {
  height: 50px;
  flex-shrink: 0;
  background-color: #134c7d;
}

header .topnav {
  overflow: hidden;
  /* background-color: #333; */
}

header .topnav a {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

header .topnav a:hover {
  background-color: #ddd;
  color: black;
}

header .topnav a.active {
  background-color: #4caf50;
  color: white;
}

header .topnav .divlink {
  float: left;
  display: block;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  cursor: pointer;
}

header .topnav .divlink:hover {
  background-color: #ddd;
  color: black;
}

header .topnav .icon {
  display: none;
}

@media screen and (max-width: 600px) {
  header .topnav a:not(:first-child) {
    display: none;
  }
  header .topnav a.icon {
    float: right;
    display: block;
  }
}

@media screen and (max-width: 600px) {
  header .topnav.responsive {
    position: relative;
  }
  header .topnav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  header .topnav.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.loader-modal {
  position: fixed;
  background: transparent;
  color: #4caf50;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
