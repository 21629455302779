p,
div {
  font-family: Lato;
}

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.signin-form-wrapper {
  display: flex;
  flex-direction: column;
  width: 280px;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
}

.signin-form-wrapper > h2 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.25em;
  color: #222;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  width: 35%;
  max-width: 80%;
  min-width: 100px;
  min-height: 400px;
  padding: 20px 40px;
  border-radius: 6px;
  box-shadow: 0px 8px 36px #222;
  background-color: #fefefe;
}

.form-wrapper > h2 {
  display: flex;
  justify-content: center;
  font-family: "Segoe UI", "Ubuntu", "Roboto", "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 2em;
  font-weight: bold;
  margin-top: 0.25em;
  color: #222;
}

form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.row {
  width: 100%;
  display: flex;
}

.column {
  float: left;
  width: 50%;
  border-width: 1px;
  border-color: #222;
  padding: 5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

select,
input {
  padding: 10px 10px;
  border-radius: 5px;
  outline: none;
  border: 1px solid #d6d1d5;
}

select::placeholder,
input::placeholder {
  font-size: 1.2em;
  font-weight: lighter;
  color: #bbb;
}

button {
  min-width: 100%;
  cursor: pointer;
  margin-right: 0.25em;
  margin-top: 0.5em;
  padding: 0.938em;
  border: none;
  border-radius: 4px;
  background-color: #4caf50;
  color: #fefefe;
}
button:hover {
  background-color: #134c7d;
  color: #fefefe;
}

.error {
  color: #db2269;
  font-size: 1em;
  display: relative;
}

.errorBold {
  color: #db2269;
  font-size: 1em;
  display: relative;
  font-weight: bold;
}

.status {
  width: 100%;
  color: #134c7d;
  font-size: 1em;
  font-weight: bold;
  display: relative;
  text-align: center;
}

.country,
.city,
.state,
.address,
.phone,
.type,
.zip,
.fullName,
.email,
.password {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  width: 100%;
}

.submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
