#customers {
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers td:nth-child(1) {
  text-align: right;
  width: 100px;
}

#customers td:nth-child(1) a {
  text-decoration: none;
}

#customers td:nth-child(1) a:hover {
  text-decoration: none;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers td:nth-child(6) {
  text-align: right;
  width: 100px;
}

#customers td:nth-child(7) {
  text-align: right;
  width: 100px;
}

#customers td:nth-child(8) {
  text-align: right;
  width: 100px;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4caf50;
  color: white;
}
